import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../component/Loader";
import {
  changeLevel,
  changeRegisterFor,
  FetchRegisterList,
} from "../class/ClassSlice";
import { getTeacherInfo } from "../slice";
import Paginations from "./../../component/Pagination";

function RegisterList() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.class.registerList);
  const total = useSelector((state) => state.class.totalRegister);
  const teacherDetail = useSelector(getTeacherInfo);
  const register_for = useSelector((state) => state.class.register_for);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  const handlePage = (val) => {
    setPage(val);
  };

  //   const getList = useCallback(() => {
  //     dispatch(GetTeacher({ page: 1, limit: 1000000 }));
  //     dispatch(SchoolListReducer({ page: 1, limit: 1000000 }));
  //   }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      FetchRegisterList({
        page: page,
        limit: 10,
        search: search,
        register_for,
        schoolId,
        teacherId: teacherDetail?.id,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page, search, register_for, schoolId, teacherId]);

  //   useEffect(() => {
  //     getList();
  //   }, [getList]);

  return (
    <article className="col-lg-12 articleClass">
      <div className="article-body store-bd-gy">
        <div className="row align-items-center col-rever">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="link-active shadow">
              <p>
                <img
                  src="assets/img/register-selected.png"
                  alt="totalRegister"
                />{" "}
                registers {total}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
            <div className="">
              <form className="has-search">
                <span className="form-search-icon form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control shadow"
                  placeholder="Search register by event/class name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </form>
              {/* <Link to="/add-register" className="">
                <img
                  src="assets/images/addBtn.png"
                  alt=""
                  className="add-new"
                />
              </Link> */}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="d-flex justify-content-between ">
              <div>
                <ul
                  class="nav nav-pills group-nav mb-3 mt-0"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class={
                        register_for === "class"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => {
                        setPage(1);
                        (async () => {
                          await localStorage.setItem("register_for", "class");
                        })();
                        dispatch(changeRegisterFor("class"));
                      }}
                    >
                      Class
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class={
                        register_for === "event"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => {
                        setPage(1);
                        (async () => {
                          await localStorage.setItem("register_for", "event");
                        })();
                        dispatch(changeRegisterFor("event"));
                      }}
                    >
                      Events
                    </button>
                  </li>
                </ul>
              </div>
              {/* <div className="d-flex justify-content-end gap-2">
                <div className="form-group selectAreaCode">
                  <div className="input-container">
                    <select
                      className="form-control pl-5 form-select selectWidth"
                      name="areacode"
                      onChange={(e) => {
                        setSchoolId(e.target.value);
                      }}
                      value={schoolId}
                    >
                      <option value="">Filter By Venue</option>

                      {venueList?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <img
                      src="assets/images/event-name.png"
                      className="input-img inputImage"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group selectAreaCode">
                  <div className="input-container">
                    <select
                      className="form-control pl-5 form-select selectWidth"
                      name="areacode"
                      onChange={(e) => {
                        setTeacherId(e.target.value);
                      }}
                      value={teacherId}
                    >
                      <option value="">Filter By Teacher</option>

                      {teacherList?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.full_name}
                        </option>
                      ))}
                    </select>
                    <img
                      src="assets/images/event-name.png"
                      className="input-img inputImage"
                      alt=""
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div class="col-sm-9 col-md-9 col-lg-10"></div>

          <div className="col-sm-12">
            <div className="table-responsive text-center ">
              <table className="table storetable position-relative">
                <thead className="text-center">
                  <tr>
                    <th scope="col">ID</th>
                    {/* <th scope="col">photo</th> */}
                    <th scope="col">
                      {register_for === "class" ? "Class" : "Event"} name
                    </th>
                    <th scope="col">Teacher name</th>
                    {/* <th scope="col">date</th>
                    <th scope="col">time</th> */}
                    {register_for === "event" ? (
                      <th scope="col">Level Name</th>
                    ) : null}
                    <th scope="col">Total bookings</th>
                    <th scope="col">Venue</th>
                    <th scope="col">Action</th>
                  </tr>
                  <tr className="border-0 bg-none invisible">
                    <td className="border-0 bg-none"></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td colSpan={7}>
                      <Loader />
                    </td>
                  ) : list?.length > 0 ? (
                    list?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item?.id}</td>
                          {/* <td>
                            <img
                              src="assets/images/kick.png"
                              className="table-img"
                              alt=""
                            />
                          </td> */}
                          <td>{item?.name ? `${item?.name}` : "NA"}</td>
                          {register_for === "event" ? (
                            <td>
                            {item?.assigned_teacher?.teacher_details?.full_name
                             ? item?.assigned_teacher?.teacher_details
                                 ?.full_name
                             : item?.teacher_details?.full_name
                             ? item?.teacher_details?.full_name
                             : "NA"} 
                         </td>
                          ) : (
                            <td> {item?.full_name ? item?.full_name : "NA"} </td>
                          )}
                          
                          {register_for === "event" ? (
                            <td>
                              {item?.level_name ? item?.level_name : "NA"}
                            </td>
                          ) : null}
                          <td>
                            {register_for === "event"
                              ? item?.booking_count
                              : register_for === "class"
                              ? item?.student_count
                              : "0"}
                          </td>
                          {/* <td>
                            <i className="fa fa-gbp" aria-hidden="true"></i> 35
                          </td> */}
                          {register_for === "event" ? (
                             <td>
                             {item?.school_details
                               ? `${item?.school_details?.name}. ${item?.school_details?.address}`
                               : "NA"}
                           </td>
                          ) : (
                            <td>
                            {
                               `${item?.school_name}. ${item?.school_address}`
                             }
                          </td>
                          )}
                          
                          <td>
                            <div className="d-flex justify-content-center action">
                              <button className="btn" title="View">
                                <Link
                                  to={
                                    register_for === "class"
                                      ? `/register/class/${item?.id}/${item?.time_slot_list?.[0]?.start_date}/${item?.time_slot_list?.[0]?.id}`
                                      : register_for === "event"
                                      ? `/register/event/${item?.id}`
                                      : null
                                  }
                                  onClick={() => {
                                    if (register_for === "event") {
                                      (async () => {
                                        await localStorage.setItem(
                                          "level",
                                          item?.level_id ? item?.level_id : ""
                                        );
                                      })();

                                      dispatch(
                                        changeLevel(
                                          item?.level_id ? item?.level_id : ""
                                        )
                                      );
                                    }
                                  }}
                                >
                                  <img src="assets/img/view.png" alt="" />
                                </Link>
                              </button>
                              {/* <button className="btn">
                                <Link to={`/edit-register/${item?.id}`}>
                                  <img src="assets/images/edit.png" alt="" />
                                </Link>
                              </button> */}
                              {/* <button
                                className="btn"
                                onClick={() => {
                                  handleShow();
                                }}
                              >
                                <img src="assets/images/delete.png" alt="" />
                              </button> */}
                              {/* <button className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle"
                                  checked=""
                                />
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>No data found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {list?.length > 0 && (
            <Paginations
              handlePage={handlePage}
              page={page}
              total={total && total}
            />
          )}
        </div>
      </div>
    </article>
  );
}

export default RegisterList;
