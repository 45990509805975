import React, { useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import { Modal } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import momentPlugin from "@fullcalendar/moment";
import {
  FetchRegisterDetail,
  changeLevel,
  changeRegisterFor,
} from "../../features/class/ClassSlice";
const isMobile = () => {
  if (window.innerWidth >= 768) {
    return false;
  } else {
    return true;
  }
};

function renderEventContent(eventInfo) {
  return (
    <>
      {/* <b>{eventInfo.timeText}</b> */}
      <span
        style={{
          backgroundColor: "none",
          fontSize: "10px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          "--webKit-line-clamp": "2",
          cursor: "pointer",
        }}
      >
        <img
          src={
            eventInfo?.event?._def?.extendedProps?.image?.includes("http")
              ? eventInfo?.event?._def?.extendedProps?.image
              : "assets/img/headerlogo.png"
          }
          alt=""
          height={"20px"}
          style={{
            borderRadius: "50%",
            objectFit: "contain",
          }}
        />{" "}
        {`${eventInfo.event.title} ${
          eventInfo?.event?._def?.extendedProps?.levelId
            ? `(${eventInfo?.event?._def?.extendedProps?.level_name}) ${moment(
                eventInfo?.event?._def?.extendedProps?.start_time,
                "hh:mm:ss"
              ).format("hh:mm a")}`
            : eventInfo?.event?._def?.extendedProps?.event_id
            ? moment(
                eventInfo?.event?._def?.extendedProps?.start_time,
                "hh:mm:ss"
              ).format("hh:mm a")
            : `${moment(eventInfo.event.startStr).format("hh:mm a")} -${moment(
                eventInfo.event.endStr
              ).format("hh:mm a")}`
        }`}
      </span>
    </>
  );
}

const CalendarComp = (props) => {
  const navigate = useNavigate();
  const calendarRef = React.createRef();
  const dispatch = useDispatch();

  const handleDateSelect = (selectInfo) => {
    let startDate = selectInfo?.startStr;

    let arr =
      selectInfo?.view?.calendar?.currentDataManager?.props?.optionOverrides
        ?.events;

    let arr2 = arr
      .filter(
        (it) => moment(it?.start)?.format("YYYY-MM-DD").toString() === startDate
      )
      ?.map((it) => it?.assigned_class_id?.toString());

    props.setClassId(arr2);
    props.setSelectedDate(startDate);
  };

  const handleEventClick = (clickInfo) => {
    let id;
    id = clickInfo?.event?._def?.extendedProps?.assigned_class_id
      ? clickInfo?.event?._def?.extendedProps?.assigned_class_id
      : clickInfo?.event?._def?.extendedProps?.event_id
      ? clickInfo?.event?._def?.extendedProps?.event_id
      : "";

    let start_time;
    let end_time;
    let time_slot_id;
    let dateFor;
    // if (clickInfo?.event?._def?.hasEnd) {
    //   start_time = moment(clickInfo.event.startStr).utc().format("HH:mm:ss");
    //   end_time = moment(clickInfo.event.endStr).utc().format("HH:mm:ss");
    //   time_slot_id = clickInfo?.event?._def?.extendedProps.time_slot_id;
    //   console.log(start_time, end_time, dateFor, time_slot_id);
    // } else {

    // }
    if (clickInfo?.event?._def?.extendedProps?.event_id) {
      start_time = moment(
        clickInfo?.event?._def?.extendedProps?.start_time
      ).format("HH:mm:ss");
    } else {
      start_time = moment(clickInfo.event.startStr).format("HH:mm:ss");
    }
    dateFor = moment(clickInfo.event.start).format("YYYY-MM-DD");
    end_time = moment(clickInfo?.event?.endStr).format("HH:mm:ss");
    time_slot_id = clickInfo?.event?._def?.extendedProps.time_slot_id;

    if (clickInfo?.event?._def?.extendedProps?.assigned_class_id) {
      (async () => {
        await localStorage.setItem("register_for", "class");
      })();
      dispatch(changeRegisterFor("class"));
      // dispatch(
      //   FetchRegisterDetail({
      //     classId: id,
      //     eventId: "",
      //     register_for: "class",
      //     date: dateFor,
      //     time: "",
      //     start_time: start_time,
      //     end_time: end_time,
      //     levelId: "",
      //   })
      // ).then(() => {
      // });
      navigate(
        `/register/class/${id}/${dateFor}/${start_time}/${end_time}/${time_slot_id}`
      );
    } else {
      (async () => {
        await localStorage.setItem("register_for", "event");
      })();
      dispatch(changeRegisterFor("event"));

      (async () => {
        await localStorage.setItem(
          "level",
          clickInfo?.event?._def?.extendedProps?.levelId
            ? clickInfo?.event?._def?.extendedProps?.levelId
            : ""
        );
      })();

      dispatch(
        changeLevel(
          clickInfo?.event?._def?.extendedProps?.levelId
            ? clickInfo?.event?._def?.extendedProps?.levelId
            : ""
        )
      );
      navigate(`/register/event/${id}/${dateFor}/${start_time}`);
    }
  };

  const handleEvents = (events) => {
    console.log("handleEvents", events);
  };

  const handleDateClick = (info) => {
    console.log("date click", info);
  };

  return (
    <div className="demo-app">
      <div className="demo-app-main">
        <FullCalendar
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            momentPlugin,
            timeGridPlugin,
          ]}
          ref={calendarRef}
          initialView="dayGridMonth"
          // editable={true}
          selectable={true}
          // selectMirror={true}
          // dayMaxEvents={isMobile ? true : false}
          weekends={true}
          windowResizeDelay={2000}
          // titleFormat={"YYYY-MM-DD :mm a"}
          // dayMaxEventRows={isMobile ? true : false}
          // eventMouseEnter={() => {}}
          events={props?.data} // alternatively, use the `events` setting to fetch from a feed
          select={handleDateSelect}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          // dateClick={handleDateClick}
          // eventsSet={handleEvents}  called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          // allDayMaintainDuration={true}
          // defaultAllDay={true}
          // datesSet={(e) => e}
          nextDayThreshold="00:00:00"
          customButtons={{
            prevButton: {
              text: "<",
              click: function () {
                let calendarApi = calendarRef?.current.getApi();

                calendarApi?.prev();
                props.setDateRange({
                  month: moment(
                    calendarApi?.currentDataManager?.data?.dateProfile
                      ?.currentRange?.start
                  )
                    .add(6, "days")
                    .format("MM"),
                  year: moment(
                    calendarApi?.currentDataManager?.data?.dateProfile
                      ?.currentRange?.start
                  )
                    .add(6, "days")
                    .format("YYYY"),
                });
              },
            },
            nextButton: {
              text: ">",
              click: function (e) {
                let calendarApi = calendarRef?.current.getApi();
                calendarApi?.next();
                props.setDateRange({
                  month: moment(
                    calendarApi?.currentDataManager?.data?.dateProfile
                      ?.currentRange?.start
                  )
                    .add(6, "days")
                    .format("MM"),
                  year: moment(
                    calendarApi?.currentDataManager?.data?.dateProfile
                      ?.currentRange?.start
                  )
                    .add(6, "days")
                    .format("YYYY"),
                });
              },
            },
          }}
          headerToolbar={{
            start: "prevButton", // will normally be on the left. if RTL, will be on the right
            center: "title",
            end: "nextButton", // will normally be on the right. if RTL, will be on the left
          }}
        />
      </div>
    </div>
  );
};

export default CalendarComp;
